<template>
  <div class="rechargeCoupon">
    <div class="coupon c_en" v-if="lang===1">
      <div class="topLogo">
        <img class="logo" src="../assets/imgs/logo2.png" alt="">
        <img class="logoText" src="../assets/imgs/whisperText2.png" alt="">
      </div>
      <div class="couponInfo">
        <div class="title">Use Coupon</div>
        <div class="exchangeNum">
          <div class="info_title">Please enter coupon code</div>
          <div class="code"><input type="text" placeholder="Please enter coupon code for this account" v-model="couponCode"></div>
          <div class="tip">You can check your coupon in "Me"-"Reward Box"</div>
        </div>
        <div class="submitBtn" @click="next()">Next</div>
      </div>
    </div>
    <div class="coupon c_ar" v-if="lang===2">
      <div class="topLogo">
        <img class="logo" src="../assets/imgs/logo2.png" alt="">
        <img class="logoText" src="../assets/imgs/whisperText2.png" alt="">
      </div>
      <div class="couponInfo">
        <div class="title">استخدام الكوبون</div>
        <div class="exchangeNum">
          <div class="info_title">أدخل كود الكوبون</div>
          <div class="code"><input type="text" placeholder="أدخل كود الكوبون الذي متاح لهذا الحساب" v-model="couponCode"></div>
          <div class="tip">يمكنك التحقق من الكوبونات في "أنا"-"صندوق المكافآت"</div>
        </div>
        <div class="submitBtn" @click="next()">التالي</div>
      </div>
    </div>
    <div class="coupon c_en" v-if="lang===3">
      <div class="topLogo">
        <img class="logo" src="../assets/imgs/logo2.png" alt="">
        <img class="logoText" src="../assets/imgs/whisperText2.png" alt="">
      </div>
      <div class="couponInfo">
        <div class="title">Kupon kullan</div>
        <div class="exchangeNum">
          <div class="info_title">Lütfen kupon kodunu girin</div>
          <div class="code"><input type="text" placeholder="Lütfen bu hesap için kupon kodunu girin" v-model="couponCode"></div>
          <div class="tip">Kuponunuzu "Ben"-"Ödül Kutusu" bölümünden kontrol edebilirsiniz.</div>
        </div>
        <div class="submitBtn" @click="next()">Sonraki</div>
      </div>
    </div>

    <!-- 填写验证码弹窗 -->
    <!-- <fillinCheckcode v-if="ifShowcheckCodespop" 
    :optType = "optType"
    :checkCodeshow="ifShowcheckCodespop" 
    :userInfo="userInfo" 
    :checkcodeType="checkcodeType"
    @sendAgainback="verify"
    @loginSuccessback="loginSuccesssaveUserdata"
    @loginSuccesslistclickback="listClickcallback"
    @closecheckcodePop="closecheckcodePop"></fillinCheckcode> -->

  </div>
</template>

<script>
import Axios from 'axios';
import { BASE_URL } from "../assets/js/config"; // assets/js/config
import { ckoHostedpayment, pmHostedpayment, sgateHostedpayment } from '../assets/js/request';
import { $debounce } from '../assets/js/commonData'
export default {
  name: '',
  components: {},
  props: {},
  data () {
    return {
      couponCode: '',
      submitData: {},
      lang: 1,
      
      // optType: 0, //控制校验弹窗title提示文案
      // ifShowcheckCodespop: false, //控制填写验证码弹窗      
      // checkcodeType: 1, //校验弹窗操作类型 1:input搜索按钮点击  3:选择搜索列表数据校验  
      // userInfo: {}
    };
  },
  watch: {},
  computed: {},
  created () {
    console.log('----------------------- 使用优惠券页面 -----------------------------')
    this.submitData = this.$route.params
    this.lang = JSON.stringify(this.submitData.payData) !== '{}' ? this.submitData.payData.lang : this.submitData.codeData.lang 
    this.userInfo = this.submitData.userInfo 
    console.log('rechargeCoupon路由参数.............', JSON.parse(JSON.stringify(this.submitData)) )
    ////console.log(this.lang, '...........lang')
    ////console.log(JSON.parse(JSON.stringify(this.submitData)))
  },
  methods: {
    next:$debounce(function(e) {
      if (this.couponCode == '') {
        if (this.lang === 1) {
          this.$toast("It can't be empty")
        }
        if (this.lang === 2) {
          this.$toast("لا يمكن أن يكون فارغاً")
        }
        if (this.lang === 3) {
          this.$toast("Boş olamaz")
        }
      } else {
        let handlerPaymentfn = {
          'cko': 'ckohostedPayment',
          'pm': 'pmhostedPayment',
          'sgate': 'sgatehostedPayment'
        }
        let {ifHosted, payChannel} = this.submitData 
        ifHosted === 1 ? this[handlerPaymentfn[payChannel]]() : this.cardPayment()
      }
    }, 2000),

    //cko托管支付
    ckohostedPayment(){
      ckoHostedpayment({
        ...this.submitData.requestParams,
        coupon_code: this.couponCode
      }).then(res => {
          console.log('cko托管支付回参.........', res)    
          if(res.code===0) {
            window.location.href = res.data.redirect_url
          } else {
            this.$toast(res.msg)
          }
      }).catch(error => {
          console.log('cko托管支付回参异常.........', error)
      })
    },
    //pm托管支付
    pmhostedPayment(){
      pmHostedpayment({
        ...this.submitData.requestParams,
        coupon_code: this.couponCode
      }).then(res => {
          console.log('pm托管支付回参.........', res)        
          if(res.code===0) {
            window.location.href = res.data.redirect_url
          } else {
            this.$toast(res.msg)
          }
      }).catch(error => {
          console.log('pm托管支付回参异常.........', error)
      })
    },
    //sgate托管支付
    sgatehostedPayment(){      
      sgateHostedpayment({
        ...this.submitData.requestParams,
        currency: 'SAR',
        coupon_code: this.couponCode
      }).then(res => {
          console.log('sgate托管支付回参.........', res)   
          if(res.code===0) {
            window.location.href = res.data.redirect_url
          } else {
            this.$toast(res.msg)
          }
      }).catch(error => {
          console.log('sgate托管支付回参异常.........', error)
      })
    },
    //卡支付
    cardPayment() {
      let url = BASE_URL + "/pay/checkout/payment/coupon";
        let data = this.submitData.codeData
        data.coupon_code = this.couponCode
        Axios.post(url, data).then(res => {
          console.log('优惠券返回..............',res.data,)
          if (res.data.code === 0) {
            let {pay_type} = res.data.data
            let coins = res.data.data.coins
            let payData = this.submitData.payData
            let routerParams = {...payData, ...this.submitData, ...res.data.data, rwchargeType: this.rwchargeType}            
            payData.coins = coins

            console.log('优惠券跳转路由参数====================', routerParams)
            this.$router.push({ name: this.submitData.routerName, query: { ...routerParams } })
          } else {
            this.$toast(res.msg || res.data.msg)
            // this.$toast({
            //     type: 'html',
            //     message: `<p>${res.data.msg}</p>`,
            //     duration: 500 
            // });
          }
        })
    }
  },
};
</script>
<style lang="scss">
.rechargeCoupon {
  .coupon {
    .topLogo {
      display: flex;
      align-items: center;
      justify-content: center;
      padding-top: 5vw;
      .logo {
        height: 10vw;
      }
      .logoText {
        height: 8vw;
        margin: 0 1vw;
      }
    }
    .couponInfo {
      width: 94vw;
      margin: auto;
      margin-top: 5vw;
      text-align: left;
      .title {
        color: #000;
        font-size: 18px;
        font-weight: bold;

        padding: 1.5vw 0;
        border-bottom: 0.5px solid #dbdbdb;
      }
      .exchangeNum {
        margin-top: 2vw;
        .info_title {
          color: #000;
          font-size: 14px;
          font-weight: 500;
        }
        .code {
          border: 1px solid #c4c4c4;
          border-radius: 4px;
          height: 12vw;
          display: flex;
          align-items: center;
          margin-top: 2vw;
          font-size: 14px;
          input {
            border: none;
            background: none;
            width: 90vw;
            margin: auto;
          }
          /* Safari，Chrome WebKit browsers */
          input::-webkit-input-placeholder {
            color: #ccc;
          }
          /* 火狐 Mozilla Firefox 4 to 18 */
          input:-moz-placeholder {
            color: #ccc;
          }
          /* 火狐 Mozilla Firefox 19+ */
          input::-moz-placeholder {
            color: #ccc;
          }
          /* Internet Explorer 10+ */
          input:-ms-input-placeholder {
            color: #ccc;
          }
        }
        .tip {
          color: rgba(0, 0, 0, 0.4);
          font-size: 14px;
          margin-top: 3vw;
          font-weight: 500;
        }
      }
      .submitBtn {
        background: #40a9ff;
        border-radius: 10vw;
        color: #fff;
        font-size: 18px;
        text-align: center;
        padding: 2.5vw 0;
        font-weight: 500;
        margin-top: 5vw;
      }
    }
  }
  .c_ar {
    direction: rtl;
    .couponInfo {
      text-align: right;
    }
  }
}
</style>